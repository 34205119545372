import React from 'react'
import Layout from "../components/layout"

const RepertoireTmp = () => (
  <Layout>
    <iframe
      title="calendar"
      style={{
        width: '100%',
        height: 600
      }}
      src="https://www.google.com/calendar/b/0/embed?showTitle=0&amp;showDate=0&amp;showPrint=0&amp;showTabs=0&amp;showCalendars=0&amp;mode=AGENDA&amp;height=600&amp;wkst=2&amp;hl=en_GB&amp;bgcolor=%23FFFFFF&amp;src=inkin.de_89d1dm8hiheqlbselki1ipikb8%40group.calendar.google.com&amp;color=%23711616&amp;ctz=Europe%2FMoscow"
      frameBorder="0"
      scrolling="no" />
  </Layout>
)

export default RepertoireTmp
